/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Aviva from "@components/sharedComponents/customersLogos/logos/Aviva.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import PulsoSalud from "@components/sharedComponents/customersLogos/logos/PulsoSalud.svg"
import ClinicaPiel from "@components/sharedComponents/customersLogos/logos/ClinicaPiel.svg"
import Idisac from "@components/sharedComponents/customersLogos/logos/Idisac.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/cx-medicina.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/experiencia-paciente-sector-salud.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/chatbots-salud.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/experiencia-cliente-clinica.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/chatbots-clinicas.jpg"

// logos testimonios
import SynlabTes from "@components/sharedComponents/testimonies/images/logos/synlab.svg"
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import AunaTes from "@components/sharedComponents/testimonies/images/logos/auna.svg"

//foto testimonio desktop
import synlabDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/synlab.png"
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import aunaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/auna.png"

//foto testimonio desktop
import synlabMobile from "@components/sharedComponents/testimonies/images/fotosMobile/synlab.png"
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import aunaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/auna.png"


//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageSalud/images/imageAccordion/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageSalud/images/imageAccordion/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageSalud/images/imageAccordion/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageSalud/images/imageAccordion/imageFour.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageIndustrias/pageSalud/images/imageConvertions/imageOne.webp"
import imageConversionsTwo from "@components/pageIndustrias/pageSalud/images/imageConvertions/imageTwo.webp"
import imageConversionsThree from "@components/pageIndustrias/pageSalud/images/imageConvertions/imageThree.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageSalud.jpg"

import TitleUnderlineStart from "@components/pageIndustrias/pageSalud/titleUnderline/titleUnderlineStart"
import imageStartContactCenter from "@components/pageIndustrias/pageSalud/images/imageStart.webp"


const titleStart = (
    <p>
        Automatiza la comunicación con tus pacientes con  <TitleUnderlineStart underline="tecnología " /><TitleUnderlineStart underline=" omnicanal" /> impulsada por IA
    </p>
)

let data = {
    start: {
        title: titleStart,
        textbody: (
            <p>
                Desde la atención al paciente hasta la gestión de citas, transforma cada punto de contacto en una experiencia positiva con autoservicio basado en Inteligencia Artificial, workflows automatizados, integraciones a todo nivel y más.
                <br /><br />
                Nuestras soluciones están diseñadas para instituciones de salud que buscan no solo adaptarse, sino liderar, brindar la seguridad y atención necesaria a sus pacientes.
            </p>
        ),
        image: imageStartContactCenter,
        textbutton: "Habla con un experto",
        link: "/hablemos/",
    },
    customersDesktop: [
        Aviva,
        Synlab,
        Auna,
        PulsoSalud,
        ClinicaPiel,
        Idisac
    ],
    customersTablet: {
        arrayOne: [Aviva, Synlab, Auna, PulsoSalud, ClinicaPiel, Idisac],
    },
    accordionRight: [
        {
            title: "Automatiza la gestión de citas",
            description: "<p>Permite a tus pacientes confirmar, reprogramar o cancelar sus citas por WhatsApp o por el canal que ellos prefieran. Todo esto sin la necesidad de tener interacción humana, gracias a la automatización de mensajes que se envían de manera automática.</p>",
            image: ImageAccordionLeftOne
        },
        {
            title: "Gestión de telesalud",
            description: "<p>Mejora la gestión de consultas a distancia a través de múltiples canales como chat, voz y video desde un solo lugar. Automatiza recordatorios y seguimientos para asegurar que tanto médicos como pacientes estén preparados para sus citas.</p>",
            image: ImageAccordionLeftTwo
        },
        {
            title: "Integración con sistemas de registros de salud",
            description: "<p>Adhiera herramientas externas como CRM, ERP o EHR para permitir la actualización y consulta automática de expedientes médicos. Esto garantiza que los datos médicos sean accesibles y seguros, tanto para pacientes, profesionales de salud y agentes de atención.</p>",
            image: ImageAccordionLeftThree,
        },
        {
            title: "Autoservicios con IA",
            description: "<p>Implementa chatbots e IVR potenciados con inteligencia artificial para responder de manera inmediata y precisa a las preguntas frecuentes de tus pacientes, guiarles a través de procedimientos administrativos y gestionar consultas iniciales.</p>",
            image: ImageAccordionLeftFour
        },
    ],
    conversions: [
        {
            title: "Clínicas",
            img: imageConversionsOne,
            text: "<p> - Envía recordatorios automáticos a los pacientes sobre sus citas programadas a través de WhatsApp, SMS, correo electrónico. <br/> - Permite a los pacientes consultar en tiempo real la disponibilidad de médicos y sus horarios, facilitando la programación de citas. <br/> - Notifica a los diferentes profesionales de salud involucrados en el cuidado del paciente según el plan de tratamiento. </p>"
        },
        {
            title: "Salud pública",
            img: imageConversionsTwo,
            text: "<p>- Envía invitaciones personalizadas a las personas elegibles para vacunarse, incluyendo información sobre fechas, ubicaciones y procedimientos. <br/> - Realiza encuestas para evaluar el conocimiento y comportamiento de la población respecto a temas de salud específicos. <br/> - Asegura la escalabilidad inmediata en todos los canales de atención para responder altas demandas de atención. </p>",
        },
        {
            title: "Farmacias",
            img: imageConversionsThree,
            text: "<p>Notifica a los pacientes cuando el medicamento ha sido enviado y proporciona un número de seguimiento. <br/> -Crea un flujo de conversación que permita solicitar la receta médica antes de procesar la compra. Notifica al paciente una vez que la receta ha sido verificada. </p>",
        },
    ],

    articles: [
        {
            image: article1,
            title: "¿Cómo ofrecer un buen servicio al cliente en salud? 8 estrategias",
            textbody:
                "Ofrece un extraordinario servicio al cliente en salud con estas 8 estrategias. ¡ENTRA AQUÍ y eleva la calidad de tus servicios!",
            link: "https://beexcc.com/blog/cx-medicina/",
            card: true,
        },
        {
            image: article2,
            title: "¿Cómo mejorar la experiencia del paciente en el sector salud?",
            textbody:
                "Te contamos 10 estrategias para mejorar la experiencia del paciente en el sector salud y puedas ofrecer un mejor servicio.",
            link: "https://beexcc.com/blog/experiencia-paciente-sector-salud/",
            card: true,
        },
        {
            image: article3,
            title: "Chatbots en salud: ¿Cómo utilizar esta herramienta en el sector?",
            textbody:
                "Te contamos cuál es la importancia del chatbot en el sector salud y por qué todos los centros médicos deben implementar uno ahora mismo.",
            link: "https://beexcc.com/blog/chatbots-salud/",
        },
        {
            image: article4,
            title: "8 estrategias para optimizar la experiencia de cliente en una clínica",
            textbody:
                "Te contamos 8 estrategias para optimizar la experiencia de cliente en una clínica que te ayudarán a fidelizar muchos más pacientes.",
            link: "https://beexcc.com/blog/experiencia-cliente-clinica/",
        },
        {
            image: article5,
            title: "Chatbot para clínicas: 8 funciones que debe cumplir",
            textbody:
                "Te contamos cuál es la importancia de utilizar chatbot para clínicas y cuáles son los casos de uso que le puedes dar en tus operaciones.",
            link: "https://beexcc.com/blog/chatbots-clinicas/",
        },
    ],
    contacts: {
        title: "Sé la empresa que genere la mejor conexión con sus pacientes",
        textbody: "Estás a un paso de revolucionar las interacciones con tus pacientes. Agenda una reunión para que uno de nuestros especialistas se involucre con tus objetivos.",
        href: "/hablemos/",
        btn: "Quiero una asesoría gratuita",
    },
    footer: {
        product: "PRODUCTOS",
        productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
        productMobileOne: "Conversations",
        linkone: "/conversations/",
        productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
        productMobileTwo: "Contact Center",
        linktwo: "/contact-center/",
        productDesktopFour: "Validation: Software de validación de teléfono.",
        productMobileFour: "Validation",
        linkfour: "/validation/",

        solution: "SOLUCIONES",
        solutionOne: "Atención al cliente",
        linkfive: "/atencion-al-cliente/",
        solutionTwo: "Telemarketing",
        linksix: "/marketing-ventas/",
        solutionThree: "Contact Center",
        linkseven: "/contact-center/",
        solutionFour: "Cobranzas",
        linkeight: "/software-cobranzas/",
        solutionFive: "WhatsApp",
        linkwhatsapp: "/whatsapp-business-api/",
        linkchatbots: "/chatbots-online/",
        solutionSix: "Chatbots",
        linkinstagram: "/chatbot-instagram/",
        solutionSeven: "Instagram",
        linkwebchat: "/chat-en-vivo-web/",
        solutionEight: "WebChat",

        company: "EMPRESA",
        companyOne: "Nosotros",
        linknine: "/nosotros/",
        companyTwo: "Casos de éxito",
        linkten: "/casos-de-exito/",
        companyThree: "Blog",
        linkeleven: "https://beexcc.com/blog/",
        companyFour: "Hablemos",
        linktwelve: "/hablemos/",
        companySix: "Documentación",
        linkdocs: "https://beexcc.com/docs/",
        companyFive: "Recursos",
        linktthirteen: "/recursos/",

        blog: "BLOG",
        blogone: "¿Cómo elegir el mejor software para call center?",
        linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

        blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
        linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

        blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
        linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

        blogfour:
            "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
        linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

        blogfive: "9 características que las herramientas de telemarketing",
        linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

        masblog: "Conoce más sobre nuestro blog",
        linkmasblog: "https://beexcc.com/blog/",

        address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
        phone: "(+511) 277-4188",
        mail: "hi@beexcc.com",
        copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
        privacyPolicy: "Política de Privacidad",
        privacyPolicyHref: "/politicas-privacidad",
        cookiesPolicy: "Política de Cookies",
        cookiesPolicyHref: "/politicas-cookies",
    },
    testimonies: [
        {
            image: AunaTes,
            title:
                "Con Beex, hemos mejorado nuestro proceso de prospección por WhatsApp, logrando mejorar en 5pp nuestra conversión",
            textbody:
                "AUNA, compañía latinoamericana de salud y bienestar con presencia en Latam, implementó WhatsApp API con Beex, logrando mejorar su tasa de conversión de leads en 5pp.",
            name: "Graciela Zapater Espejo",
            post: "Especialista Digital",
            link: "/casos-de-exito/auna/",
            imgDesktop: aunaDesktop,
            imgMobile: aunaMobile,
            metrics: [
                {
                    metric: "5pp",
                    descripcion: " mejoró su tasa de conversión de leads",
                    color: "#FFB800",
                },
            ],
        },
        {
            image: AvivaTes,
            title:
                "Con los workflows de WhatsApp mejoramos en +30% el tiempo de agenda de citas",
            textbody:
                "Aviva, parte del grupo Intercorp, clínica multiespecialidad con diferentes servicios en salud, ha logrado automatizar su proceso de citas médicas con chatbots en WhatsApp.",
            name: "Luis Ordinola",
            post: "Coordinador de mejora de procesos",
            link: "/casos-de-exito/clinica-aviva/",
            imgDesktop: avivaDesktop,
            imgMobile: avivaMobile,
            metrics: [
                {
                    metric: "+30%",
                    descripcion: "mejoró el tiempo de agenda de citas",
                    color: "#FFB800",
                },
                {
                    metric: "+20%",
                    descripcion: "ahora representa WhatsApp como canal para agendar citas",
                    color: "#5831D8",
                },
            ],
        },
        {
            image: SynlabTes,
            title:
                "Beex nos ha permitido resolver el 20% de  atenciones sin necesidad de agentes",
            textbody:
                "SYNLAB Perú, operación Peruana del laboratorio clínico más grande de Europa, ha mejorado su tiempo de primera respuesta en 95% gracias a las automatizaciones de Beex.",
            name: "Yesenia Sánchez Rodríguez",
            post: "Jefa de Servicio al Cliente",
            link: "/casos-de-exito/synlab/",
            imgDesktop: synlabDesktop,
            imgMobile: synlabMobile,
            metrics: [
                {
                    metric: "95%",
                    descripcion: "mejoró su tiempo de primera respuesta",
                    color: "#FFB800",
                },
                {
                    metric: "20%",
                    descripcion: "tickets cerrados sin necesidad de un agente",
                    color: "#5831D8",
                },
                {
                    metric: "20 seg",
                    descripcion: "disminuyeron sus respuestas telefónicas",
                    color: "#00CA56",
                },
            ],
        },
    ],
    bannerVideo: {
        title: "¿Cómo automatizar y brindar experiencias únicas en el sector salud?",
        description: "<p>Descubre cómo simplificar y mejorar la atención médica con automatización inteligente. Explora nuevas formas de proporcionar experiencias únicas en el sector salud. Optimiza procesos y maximiza la eficiencia para un cuidado más efectivo y personalizado. <br /><br /> Conoce cómo la tecnología transforma la manera en que interactuamos y cuidamos de la salud de todos.</p>",
        image: imageVideo
    },

}

export default data
